/* LandingPage.css */

/* BASIC RESETS & GLOBAL */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  font-family: 'Inter', sans-serif;
  color: #3c3c3c;
  background: #fbfbfb;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  cursor: pointer;
}

.landing-container {
  display: flex;
  flex-direction: column;
}

/* UTILITY CLASSES */
.button {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  border-radius: 3rem;
  font-weight: 600;
  transition: background-color 0.2s ease, transform 0.2s ease;
  border: none;
  text-align: center;
}

.button:hover {
  transform: scale(1.03);
}

.primary-button {
  background-color: #6b4efc;
  color: #fff;
}

.primary-button:hover {
  background-color: #583bd9;
}

.secondary-button {
  border: 2px solid #6b4efc;
  color: #6b4efc;
  background: transparent;
}

.secondary-button:hover {
  background-color: rgba(107, 78, 252, 0.08);
}

/* HEADER */
.site-header {
  background: #fff;
  border-bottom: 1px solid #eee;
}

.main-nav {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo-link {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.logo-img {
  height: 40px;
}

.logo-text {
  font-weight: 700;
  font-size: 1.2rem;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 1.5rem;
}

.nav-links li a {
  font-weight: 500;
}

.nav-links .primary-button {
  font-size: 0.95rem;
}

/* HERO SECTION */
.hero-section {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-width: 1200px;
  margin: 2rem auto;
  padding: 2rem 1.5rem;
}

.hero-content {
  flex: 1;
  min-width: 300px;
}

.hero-title {
  font-size: 2.4rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.hero-subtext {
  font-size: 1.1rem;
  line-height: 1.5;
  margin-bottom: 2rem;
  color: #525252;
}

.hero-cta {
  margin-bottom: 2rem;
}

.hero-illustration {
  flex: 1;
  text-align: center;
  min-width: 280px;
}

.hero-illustration img {
  max-width: 100%;
  height: auto;
}

/* MISSION / TESTIMONIAL */
.mission-section {
  max-width: 1000px;
  margin: 3rem auto;
  padding: 0 1.5rem;
  display: grid;
  gap: 2rem;
  grid-template-columns: 1fr 1fr;
}

.mission-text,
.testimonial {
  background: #fff;
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
}

.mission-text h3 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

.testimonial h4 {
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.testimonial-quote {
  font-size: 1rem;
  line-height: 1.5;
  color: #666;
  margin: 1rem 0;
}

.testimonial-user {
  font-style: italic;
  color: #999;
}

/* FEATURES SECTION */
.features-section {
  max-width: 1200px;
  margin: 3rem auto;
  padding: 0 1.5rem;
  text-align: center;
}

.features-section h2 {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.section-intro {
  color: #666;
  font-size: 1.1rem;
  margin-bottom: 3rem;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 1.5rem;
}

.feature-card {
  background: #fff;
  border-radius: 8px;
  text-align: left;
  padding: 1.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
}

.feature-card img {
  width: 40px;
  height: 40px;
  margin-bottom: 1rem;
}

.feature-card h4 {
  margin-bottom: 0.5rem;
  font-weight: 600;
}

/* FAQ SECTION */
.faq-section {
  max-width: 800px;
  margin: 4rem auto;
  padding: 0 1.5rem;
  text-align: center;
}

.faq-section h2 {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.faq-item {
  text-align: left;
  margin: 1rem 0;
  border-bottom: 1px solid #eee;
  padding-bottom: 1rem;
}

.faq-question {
  font-weight: 600;
  background: none;
  border: none;
  width: 100%;
  text-align: left;
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
  color: #3c3c3c;
}

.faq-answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease, padding 0.3s ease;
  font-size: 0.95rem;
  line-height: 1.6;
  color: #555;
}

.faq-answer.open {
  max-height: 300px;
  /* large enough for typical text */
  padding-top: 0.5rem;
}

/* CTA SECTION */
.cta-section {
  background: #faf9fe;
  text-align: center;
  padding: 3rem 1.5rem;
}

.cta-text h3 {
  font-size: 1.8rem;
  margin-bottom: 0.5rem;
}

.cta-buttons {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  gap: 1rem;
}

/* FOOTER */
.site-footer {
  background-color: #f0f0f0;
  padding: 2rem 1.5rem;
  margin-top: 2rem;
}

.footer-main {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)) 1.5fr;
}

.footer-col h5 {
  margin-bottom: 0.8rem;
  font-size: 1rem;
  font-weight: 600;
}

.footer-col ul {
  list-style: none;
  padding: 0;
}

.footer-col li {
  margin-bottom: 0.5rem;
}

.footer-col a:hover {
  text-decoration: underline;
}

.brand-col {
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}

.brand-col .footer-logo {
  width: 120px;
  margin-bottom: 1rem;
}

/* RESPONSIVE */
@media (max-width: 768px) {

  .hero-section,
  .mission-section,
  .features-section,
  .faq-section {
    padding: 1rem;
  }

  .hero-section {
    flex-direction: column;
  }

  .mission-section {
    grid-template-columns: 1fr;
  }

  .footer-main {
    grid-template-columns: 1fr 1fr;
  }

  .brand-col {
    align-items: flex-start;
    margin-top: 1rem;
  }
}